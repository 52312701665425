html {
  font-size: 62.5%;
}

h1 {
  font-size: 3rem;
}
p {
  font-size: 1.6rem;
  line-height: 1.5;
}
.heading {
  margin: 2rem 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.heading::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translate(-50%);
  width: 70%;
  height: 0.2rem;
  background-color: #ed1c24;
  transition: width 0.2s;
}

.heading:hover::after {
  width: 100%;
}
.main-btn {
  display: inline-block;
  padding: 1.2rem 3rem;
  position: relative;
  background-color: #01406b;
  color: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 700;
  border: none;
  margin: 2rem 0 0 0;
  background-image: linear-gradient(to right, #01406c, #0098ff);
  border: none;
  background-color: #01406c;
  border-radius: 3rem !important;
  color: #fff !important;
}
.main-btn:hover {
  cursor: pointer;
  scale: 1;
}

.Toastify {
  font-size: 1.8rem !important;
}

.footer-email {
  font-size: 1.6rem;
  color: #fff;
  letter-spacing: 0.8px;
  transition-duration: 0.3s;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  line-height: 1.5;
  text-decoration: none;
  cursor: pointer;
}

.second-btn {
  font-size: 1.6rem;
  color: #ed1c24;
  background-color: transparent;
  font-weight: 500;
  border: none;
  margin: 1rem 0 0 0;
  border: 1px solid #ed1c24;
  padding: 0.7rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.second-btn:hover {
  background-color: #ed1c24;
  color: #fff;
}
.country-section {
  margin-top: 9.5rem;
}

.countries-text-container {
  padding: 2rem 5rem;
}

.country-subheading {
  font-size: 1.8rem;
  margin: 0.5rem 0;
  font-weight: 600;
  color: #01406b;
}

.country-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2b3082;
  text-align: center;
  min-height: 26rem;
  font-size: 3.9rem;
  color: #fff;
  padding: 0;
  margin: 0 0 4px 0;
  text-transform: inherit;
  font-weight: 600;
  letter-spacing: 1px;
}

.country-top-title {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 3.9rem;
}

.country-quote {
  color: red;
}

.country-subtitle {
  text-align: center;
  margin: 1.5rem 0 1rem 0;
}

.country-subtitle div {
  font-size: 2.5rem;
  margin: 0.5rem 0;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.country-sub-container {
  margin: 1.5rem 0;
}

.form-select {
  font-size: 1.6rem;
}

@media only screen and (min-width: 1600px) {
  html {
    font-size: 160.5%;
  }
}

@media only screen and (max-width: 1000px) {
  .services-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .country-container {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media only screen and (max-width: 768px) {
  .testimonial-container {
    flex-direction: column !important;
  }
  .testiImg {
    margin-top: 2rem !important;
  }
  .testi3img {
    margin-top: 2rem !important;
  }
  .testi-text-container {
    width: 100% !important;
  }
  .css-qwtrx-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
    max-width: 25rem !important;
  }
  .container {
    flex-direction: column-reverse;
  }
  .one {
    width: 100% !important;
  }
  .two {
    width: 100% !important;
    margin: auto auto !important;
  }
  .introduction-video {
    width: 50rem !important;
    height: 30rem !important;
  }
  .services-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .country-container {
    grid-template-columns: 1fr 1fr;
  }
  .card-section-container {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column-reverse;
  }
  .one {
    width: 100%;
  }
  .two {
    width: 100%;
    margin: auto auto;
  }
  .introduction-video {
    width: 30rem !important;
    height: 20rem !important;
  }
  .services-container {
    grid-template-columns: 1fr !important;
    margin: 2rem 0 !important;
  }
  .country-container {
    grid-template-columns: 1fr !important;
  }
  .country-container {
    padding: 0 !important;
  }
  .contact-row {
    width: 100% !important;
  }
  .card-section-container {
    grid-template-columns: 1fr !important;
  }
  .header {
    padding: 0.3rem 2rem !important;
  }
}

@media only screen and (max-width: 500px) {
  .services-container {
    grid-template-columns: 1fr !important;
  }
  .form-item-container {
    flex-direction: column !important;
    margin: 0 !important;
  }
  .form-item-container .form-item {
    width: 100% !important;
    margin: 0.5rem 0;
  }

  .services-content-section,
  .company-info {
    padding: 0px 2rem !important;
  }
  .home-section,
  .services-page-section,
  .about-section,
  .contact-section,
  .carsoul-section {
    margin-top: 8.5rem !important;
  }
  .Introduction-section {
    padding: 2rem 3rem 3rem 3rem !important;
  }
  .footer-section {
    padding: 3rem !important;
  }
  .callback-section {
    padding: 3rem !important;
  }
  .services-section {
    padding: 1.5rem 3rem 4rem 3rem !important;
  }
  .country-container {
    margin: 2rem 0 !important;
  }
  .testiImg {
    height: 23rem !important;
  }
  .testi3img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 375px) {
  .container {
    flex-direction: column-reverse;
  }
  .one {
    width: 100%;
  }
  .two {
    width: 100%;
    /* margin: auto auto; */
  }
  .introduction-video {
    width: 250px !important;
    height: 180px !important;
  }
  .testimonials-section {
    padding: 10px 20px 50px 20px !important;
  }
  .testimonials-section .slick-next::before,
  .testimonials-section .slick-prev::before {
    font-size: 30px !important;
  }
  .testimonials-section .slick-prev {
    left: -19px;
  }

  .services-content-section,
  .company-info {
    padding: 0px 20px !important;
  }
  .Introduction-section {
    padding: 20px 20px 30px 20px !important;
  }
  .footer-section {
    padding: 3rem 2rem !important;
  }
  .callback-section {
    padding: 3rem 2rem !important;
  }
  .services-section {
    padding: 1.5rem 2rem 4rem 2rem !important;
  }
  .mobile-dropdown-content {
    left: -75% !important;
  }
}
