.Introduction-section {
  padding: 2rem 5rem 3rem 5rem;
  text-align: center;
  background-color: #fff;
}
.Introduction-section .text {
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 1.8rem;
}
.country-text {
  font-size: 1.6rem;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.one {
  width: 100%;
}
.two {
  width: 40%;
}
.introduction-video {
  height: 100%;
  width: 100%;
}

.country-container {
  margin: 4rem 0 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}
.country-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
}

.country-item {
  border: 1px solid #d0d0d0;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.country-item:hover {
  transform: scale(1.02);
}

.country-logo {
  width: 100%;
  height: 30rem;
  overflow: hidden;
}
.subheading {
  font-size: 1.8rem;
  margin: 1rem 0;
}

.country-title {
  font-size: 2rem;
  color: #007bff;
  background-color: transparent;
  font-weight: 500;
}
.img-container {
  border-radius: 0.5rem;
}
