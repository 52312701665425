.contact-section {
  margin-top: 9.5rem;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ab8442;
  text-align: center;
  min-height: 26rem;
  font-size: 3.9rem;
  color: #fff;
  padding: 0;
  margin: 0 0 0.4rem 0;
  text-transform: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  background-image: url("../../assests/images/aboutus-banner.jpg");
}

.contact-form-heading {
  margin: 1rem 0;
  font-size: 2.2rem;
  font-weight: 500;
}

.contact-heading-section {
  text-align: center;
}

.contact-page {
  display: flex;
  background-color: #031f4b;
  padding: 0 5rem;
}
.contact-page h2 {
  font-size: 2rem;
}
.contact-page .one {
  width: 50%;
  background-color: #031f4b;
  color: #fff;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.contact-page .two {
  background-color: #031f4b;
  width: 50%;
  padding: 3.5rem;
  color: #fff;
}
.contact-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
  border: 1px solid #fff;
  display: grid;
  place-items: center;
  background-color: #fff;
  color: #000;
  border-radius: 1rem;
  padding: 2rem 0;
  -webkit-box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
}

.sub-heading {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.contact-row {
  width: 50%;
}

.item {
  display: flex;
  gap: 0.5rem;
}

.one p {
  margin-bottom: 0;
  font-size: 1.6rem;
  letter-spacing: 0.6px;
  line-height: 1.6;
}
.one h2 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.icon {
  font-size: 2.2rem !important;
}

.text {
  letter-spacing: 1px;
  font-size: 1.6rem;
}

.two h2 {
  font-size: 2.4rem;
  font-weight: 600;
}

.form {
  width: 80%;
  margin: 1rem 0;
}
.mb-3 {
  margin-bottom: 0.6rem !important;
}

label {
  font-size: 1.8rem;
  display: block;
}
.contact-page .form-control {
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #000;
}

input:active,
input:focus {
  border: none !important;
}

.flex {
  display: flex;
  column-gap: 1.5rem;
}

.contact-btn {
  font-size: 1.6rem;
  color: #ed1c24;
  background-color: transparent;
  font-weight: 500;
  border: none;
  margin: 1rem 0 0 0;
  border: 1px solid #ed1c24;
  padding: 0.7rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.contact-btn:hover {
  background-color: #ed1c24;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .contact-page {
    flex-direction: column;
  }
  .form-container {
    width: 100%;
    border-radius: 0px;
  }
  .contact-page {
    padding: 0;
  }
  .contact-page .two {
    border-radius: 1rem;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .contact-page .two {
    background-color: #fff !important;
    width: 100%;
  }
  .form {
    width: 100%;
  }
  .one {
    width: 100%;
  }
  .two {
    width: 100%;
  }
  .contact-page {
    flex-direction: column;
  }
  .flex {
    flex-direction: column;
  }
  .form-container {
    box-shadow: none;
    border: none;
  }
}
