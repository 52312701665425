.carsoul-section {
  margin-top: 96px;
}
.bannerImg {
  width: 100%;
  height: auto;
  /* height: 400px; */
}
.slick-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carsoul-section .slick-next {
  background: rgba(0, 0, 0, 0.8) !important;
  padding: 20px !important;
  color: #fff !important;
  right: 20px !important;
}

.carsoul-section .slick-prev {
  background: rgba(0, 0, 0, 0.8) !important;
  padding: 20px !important;
  color: #fff !important;
  left: 20px !important;
  z-index: 100;
}
.carsoul-section .slick-next:before {
  content: "" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carsoul-section .slick-prev:before {
  content: "" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
