.services-section {
  background: #eaeef9;
  padding: 1.5rem 5rem 4rem 5rem;
  text-align: center;
}
.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  margin: 2rem;
}
.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.5rem;
  -webkit-box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.service-card:hover {
  transform: scale(1.05);
}

.service-card-image-container {
  width: 100%;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.service-card img {
  height: auto;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
}

.service-heading {
  margin-bottom: 0;
  font-size: 1.8rem;
  color: #01406c;
  font-weight: 600;
}

.service-text {
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  letter-spacing: 0.5px;
  padding: 0 2rem;
}
.card-btn {
  font-size: 1.6rem;
  color: #ed1c24;
  background-color: transparent;
  font-weight: 500;
  border: none;
  margin: 0.5rem 0 1.5rem 0;
  border: 1px solid #ed1c24;
  padding: 0.7rem 1.5rem;
  border-radius: 0.5rem;
}

.card-btn:hover {
  background-color: #ed1c24;
  color: #fff;
}
