.callback-section {
  padding: 5rem;
  display: grid;
  grid-template-columns: 50rem 1fr;
  column-gap: 3rem;
  background-color: #f6f6f6;
}
.callback-section p {
  line-height: 1.5;
}
.callback-haeding {
  text-align: center;
}
.callback-one img {
  height: 60rem;
  width: auto;
}

.callback-two p {
  margin: 1rem 0;
}
.form-item {
  display: flex;
  flex-direction: column;
}
.error {
  color: red;
  font-size: 1.6rem;
  margin-top: 0.8rem;
}
.form-item-container {
  display: flex;
  column-gap: 2rem;
  margin: 2rem 0;
}

.form-item-container input {
  font-size: 1.6rem;
  padding: 1rem;
  width: 100%;
  margin-top: 0.5rem;
}

.form-item-container .form-item {
  width: 50%;
}
label {
  margin-bottom: 0.5rem;
}
.form-select {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  padding: 1.2rem;
}

.form-select option {
  font-size: 1.6rem;
  padding: 1rem;
  width: 100%;
  margin-top: 0.5rem;
}
.txt-area textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.6rem;
}

@media only screen and (max-width: 1000px) {
  .callback-section {
    grid-template-columns: 1fr;
  }
  .callback-one img {
    width: 100%;
    height: auto;
  }
}
