.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ab8442;
  text-align: center;
  min-height: 26rem;
  font-size: 3.9rem;
  color: #fff;
  padding: 0;
  margin: 0 0 0.4rem 0;
  text-transform: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  background-image: url("../../assests/images/aboutus-banner.jpg");
}
.company-info {
  padding: 3rem 5rem;
}

.heading-container {
  text-align: center;
}
.about-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.about-country {
  display: flex;
  align-items: center;
  overflow: hidden;
  -webkit-box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  column-gap: 1.5rem;
  cursor: pointer;
}
.country-flag {
  height: 5rem !important;
  width: 10rem !important;
}
.company-info img {
  height: 40rem;
  width: auto;
}
.about-title {
  text-align: center;
  font-size: 3.4rem;
  color: #000b4a;
  padding: 0;
  margin: 0 0 2rem 0;
  font-weight: 700;
}

.about-txt {
  font-size: 1.6rem;
  color: #535353;
  padding: 0;
  margin: 0px 0 1.8rem 0;
  letter-spacing: 0.6px;
  line-height: 1.5;
}

.about-list li {
  list-style: none;
  font-size: 1.8rem;
  color: #535353;
  padding: 0.7rem;
  margin: 0.5rem 0;
  letter-spacing: 0.6px;
  min-width: 24rem;
}
.guidence-section {
  padding: 1rem 5rem;
}
.guidence-section h4 {
  margin: 0.5rem 0;
  font-size: 2rem;
}

@media only screen and (max-width: 800px) {
  .company-info {
    grid-template-columns: 1fr;
  }
  .company-info img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .company-info {
    grid-template-columns: 1fr;
  }
  .company-info img {
    width: 100%;
    height: auto;
  }
}
