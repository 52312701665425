.about-section {
  margin-top: 9.5rem;
}

.ourteamheading {
  text-align: center;
}
.team-section {
  text-align: center;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ab8442;
  text-align: center;
  min-height: 26rem;
  font-size: 3.9rem;
  color: #fff;
  padding: 0;
  margin: 0 0 0.4rem 0;
  text-transform: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  background-image: url("../../assests/images/aboutus-banner.jpg");
}

.card-section {
  display: flex;
  flex-direction: column;
  background-color: #f6f4f9 !important;
  border-radius: 1rem !important;
  cursor: pointer;
  padding: 2rem;
}

.team-profile-img {
  border-radius: 50% !important;
  height: 20rem;
  width: auto;
}

.card-section-container {
  padding: 0 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  margin: 3rem 0;
  justify-content: center;
}

.card-footer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
}

.team-profile-container {
  padding: 0px 2rem 0 2rem;
}

.card-name {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1.8rem;
}

.card-designation {
  color: #726d7b;
  font-size: 1.6rem;
  margin: 0.5rem 0 0 0;
  font-weight: 500;
}

.team-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.team-btn {
  font-size: 1.6rem;
  color: #ed1c24;
  background-color: transparent;
  font-weight: 500;
  border: none;
  margin: 1rem 0 0 0;
  border: 1px solid #ed1c24;
  padding: 0.7rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.team-btn:hover {
  background-color: #ed1c24;
  color: #fff;
}

.team-text {
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin: 1.5rem 0;
}
