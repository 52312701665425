.header {
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 1px 2px 2px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 4rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
}
.active {
  color: #ed1c24 !important; /* Replace with the desired color for the active link */
}

.menu-icon {
  font-size: 3.5rem !important;
}

.close-icon-container {
  display: flex;
  justify-content: end;
}

.drawer-section {
  padding: 2rem;
}

.services-anchor {
  text-decoration: none;
  color: black;
}

.mobile-drawer {
  display: none;
}

nav {
  display: flex;
  align-items: center;
}

.header-select {
  border: none;
}

.header-select:focus {
  border: none;
}

.header-select:active {
  border: none;
}
.header-select:hover {
  border: none;
}
.navlink {
  text-decoration: none;
  padding: 2rem;
  color: #000;
  font-weight: 500;
  font-size: 1.6rem;
}
.navlink:hover,
.dropdown-content .navlink {
  color: #ed1c24;
}
.logo {
  cursor: pointer;
  height: auto;
  width: 15rem;
}

.header-select {
  text-decoration: none;
  padding: 2rem;
  color: #000;
  font-weight: 500;
  font-size: 1.6rem;
}

.header-select option {
  padding: 2rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.mobile-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 20rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 25rem;
  overflow-y: scroll;
}

.mobile-dropdown-content {
  position: absolute;
  top: 150%;
  left: -20%;
  min-width: 15rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 25rem;
  overflow-y: scroll;
}
.dropdown-btn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.dropdown-txt {
  display: flex;
}
.header-icon {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.dropdown-content .navlink {
  border-bottom: 1px solid rgb(161, 154, 154);
  color: black;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  display: block;
}

.mobile-dropdown-content .mobile-navlink {
  border-bottom: 1px solid rgb(161, 154, 154);
  color: black;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  display: block;
  font-size: 1rem;
}

.dropdown-content .navlink:hover {
  color: #ed1c24;
}

.dropdown-content .navlink:last-child {
  border: none !important;
}

.mobile-navlink {
  font-size: 1rem;
}

.menu-container {
  display: none;
}

.mobile-nav {
  display: none;
  padding: 0px 4rem;
  border-top: 1px solid;
}

@media only screen and (max-width: 768px) {
  nav {
    display: none;
  }
  .menu-container {
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-nav {
    display: flex;
    flex-direction: column;
  }
}
