.footer-section {
  padding: 3rem 5rem;
  background-color: #54389e;
}

.footer-subheading {
  font-size: 2rem;
  color: #fff;
  padding: 0;
  margin: 0 0 1rem 0;
  font-weight: 600;
  letter-spacing: 0.7px;
}

.footer-first-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #fff;
  column-gap: 1.2rem;
}

.footer-first-container {
  text-transform: capitalize;
  font-size: 1.6rem;
  color: #fff;
  letter-spacing: 0.8px;
  transition-duration: 0.3s;
}

.icons-container-item {
  text-transform: capitalize;
  font-size: 1.6rem;
  color: #fff;
  letter-spacing: 0.8px;
  transition-duration: 0.3s;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.copyright-txt {
  font-size: 1.4rem;
  color: #d4cbcb;
  padding: 0;
  margin: 0;
  letter-spacing: 0.8px;
  font-weight: 200;
  text-align: center;
  background-color: #2d3845;
  padding: 0.7rem 0;
}

.social-img {
  height: 2.5rem;
  width: auto;
}

.social-icons-container {
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
}

hr {
  margin: 3rem 0;
}
.second-container-item {
  display: flex;
  gap: 2rem;
}
.second-container-item p {
  text-transform: capitalize;
  font-size: 1.6rem;
  color: #fff;
  letter-spacing: 0.8px;
  transition-duration: 0.3s;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  line-height: 1.5;
}
.icon {
  color: #fff;
  font-size: 4rem;
}
.second-container-subheading {
  font-size: 2rem;
  color: #fff;
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-weight: 600;
  letter-spacing: 0.7px;
}

.footer-second-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.whats-app-icon {
  position: fixed;
  bottom: 3rem;
  left: 1.6rem;
  z-index: 99999;
  height: 4.5rem;
  width: 4.5rem;
  background-color: #25d366;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.3rem;
  border-radius: 0.8rem;
}

.scroll-icon-container {
  position: fixed;
  bottom: 3rem;
  right: 1.6rem;
  z-index: 99999;
  height: 4.5rem;
  width: 4.5rem;
  background-color: #017bcf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.3rem;
  border-radius: 0.8rem;
}

.scroll-icon {
  color: #fff;
  font-size: 3rem;
  transform: rotate(90deg);
  font-size: 2.5rem !important;
}

.whatsapp-icon {
  color: #fff;
  font-size: 2.5rem !important;
}

.whatsapp-anchor {
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer-second-container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
  .footer-first-container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
