.testimonials-section {
  padding: 2rem 5rem 7rem 5rem;
  background-color: #2d3845;
  color: #fff;
}

.testmonials-heading {
  margin: 2rem 0 2rem 0;
  text-align: center;
  width: 100%;
  color: #0e1b2c;
}

.testimonials-section .slick-slide > div {
  margin: 0 1rem;
}
.testimonials-section .slick-list {
  margin: 0 -1rem;
}

.student-speak-section p {
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 1.5rem;
}
.student-card {
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  position: relative;
}
.student-img-container {
  width: 10rem;
}
.title-container {
  text-align: center;
  margin-bottom: 2rem;
}
.student-bref {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
.bold {
  font-weight: 600;
}
.student-photo {
  height: 10rem;
  width: auto;
}
.quote {
  margin: 1rem 0;
}

.description {
  margin: 1rem 0;
}

.testimonials-section .slick-next::before {
  font-size: 3rem;
  color: #fff;
  right: 20px !important;
}

.testimonials-section .slick-prev::before {
  font-size: 3rem;
  left: 2rem !important;
  color: #fff;
}

.testimonial-container {
  display: flex !important;
  justify-content: center;
  column-gap: 2rem;
  margin-top: 1rem;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.testiImg {
  height: 30rem;
}

.testi3img {
  height: 23.5rem;
}
.stars {
  display: flex;
}
.staricon {
  color: #eea200;
  font-size: 2.5rem !important;
}

.testi-text {
  line-height: 1.6;
  letter-spacing: 1px;
  font-size: 1.5rem;
}
.testi-student-title {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 500;
}

.testi-text-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 50%;
}

.testimonials-section .slick-next {
  left: 98.5% !important;
  z-index: 900;
}
.testimonials-section .slick-prev {
  z-index: 900;
}

@media only screen and (max-width: 500px) {
  .student-speak-section {
    grid-template-columns: 1fr;
  }
  .student-card {
    width: 100%;
  }

  .student-bref {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .student-photo {
    position: static;
    height: 9rem;
    width: auto;
  }
  .quote {
    margin: 1rem 0;
  }
  .testimonials-section .slick-next {
    left: 95.5% !important;
  }
}
