.services-page-section {
  margin-top: 9.6rem;
  font-size: 1.8rem;
}
.services-content-section {
  padding: 2rem 5rem;
}

.service-sub-section {
  margin: 2rem 0;
}

.services-content-section ul {
  padding: 0 3rem;
}
.services-content-section li {
  font-size: 1.6rem;
  margin: 0.3rem 0;
}

.services-content-section p {
  font-size: 1.6rem;
  line-height: 1.5;
}

.services-content-section h6 {
  font-size: 2rem;
  margin: 0.5rem 0;
}

.service-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ab8442;
  text-align: center;
  min-height: 26rem;
  font-size: 3.9rem;
  color: #fff;
  padding: 0;
  margin: 0 0 0.4rem 0;
  text-transform: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  background-image: url("../../assests/images/services.jpg");
  background-size: cover;
}
