.title {
  font-size: 2.2rem;
  text-transform: capitalize;
  text-align: center;
  margin: 1rem 0;
  font-weight: 700;
}
.close-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  padding: 20px;
  background-color: #07456f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-icon {
  color: #fff;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
}

.body {
  font-size: 1.6rem;
  letter-spacing: 0.6px;
  line-height: 1.5;
}

.cls-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.cls-btn {
  font-size: 16px;
  color: #ed1c24;
  background-color: transparent;
  font-weight: 500;
  border: none;
  margin: 10px 0 0 0;
  border: 1px solid #ed1c24;
  padding: 7px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cls-btn:hover {
  background-color: #ed1c24;
  color: #fff;
}
